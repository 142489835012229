import {on} from 'delegated-events'

on('upload:setup', '.js-upload-marketplace-listing-hero-card-background', function (event) {
  const {form} = event.detail

  const listingID = event.currentTarget.getAttribute('data-marketplace-listing-id')
  if (listingID) {
    form.append('marketplace_listing_id', listingID)
  }
})

on('upload:complete', '.js-upload-marketplace-listing-hero-card-background', function (event) {
  const {attachment} = event.detail
  document.querySelector<HTMLInputElement>('.js-marketplace-listing-hero-card-background-id')!.value = attachment.id
  document.querySelector<HTMLElement>('.js-hero-listing-container')!.style.backgroundImage = `url(${attachment.href})`
})
