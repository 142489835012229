import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'

async function updateQuantity(e: Event) {
  const field = e.target as HTMLInputElement
  const quantity = parseInt(field.value, 10).toFixed()
  const dataUrl = field.getAttribute('data-url')!
  const url = new URL(dataUrl, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.set('quantity', quantity)
  url.search = params.toString()

  const html = await fetchSafeDocumentFragment(document, url.toString())
  const el = document.querySelector<HTMLElement>('.js-marketplace-order-preview')!
  el.textContent = ''
  el.appendChild(html)
}

observe('.js-marketplace-order-quantity', function (el) {
  el.addEventListener('input', updateQuantity)
})

on('change', 'input.js-grant-oap-checkbox', function (event) {
  const input = event.currentTarget as HTMLInputElement
  const completeOrderButton = document.querySelector<HTMLButtonElement>('.js-marketplace-complete-order')!
  if (completeOrderButton) completeOrderButton.disabled = !input.checked
})

on('click', '.js-marketplace-complete-order', function (event) {
  event.preventDefault()

  const button = event.currentTarget as HTMLButtonElement
  const form = button.form
  const billingModal = document.querySelector('.js-marketplace-billing-modal')
  const showBillingModal = button.getAttribute('data-show-billing-modal')

  // If viewer does not have valid payment info, open the billing modal
  if (showBillingModal === 'true' && billingModal) {
    billingModal.setAttribute('open', 'true')
  } else {
    if (form) form.submit()
  }
})
