import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'

type PriceModel = 'FREE' | 'DIRECT_BILLING' | 'FLAT_RATE' | 'PER_UNIT' | string

function showPreviewOfPlan(planEditor: Element, previewPane: Element) {
  const nameEl = previewPane.querySelector<HTMLElement>('.js-marketplace-plan-name')!
  const name = planEditor.querySelector<HTMLInputElement>('.js-marketplace-plan-name-field')!.value
  nameEl.textContent = name

  const freeTrialContainer = previewPane.querySelector<HTMLElement>('.js-marketplace-free-trial-container')!
  const noFreeTrialContainer = previewPane.querySelector<HTMLElement>('.js-marketplace-no-trial-container')!
  const directBillingContainer = previewPane.querySelector<HTMLElement>('.js-marketplace-direct-billing-container')!
  const hasFreeTrial = planEditor.querySelector<HTMLInputElement>('.js-marketplace-free-trial-field')!.checked
  if (hasFreeTrial) {
    /* eslint-disable-next-line github/no-d-none */
    freeTrialContainer.classList.remove('d-none')
    /* eslint-disable-next-line github/no-d-none */
    directBillingContainer.classList.add('d-none')
    /* eslint-disable-next-line github/no-d-none */
    noFreeTrialContainer.classList.add('d-none')
  } else {
    /* eslint-disable-next-line github/no-d-none */
    noFreeTrialContainer.classList.remove('d-none')
    /* eslint-disable-next-line github/no-d-none */
    freeTrialContainer.classList.add('d-none')
    /* eslint-disable-next-line github/no-d-none */
    directBillingContainer.classList.add('d-none')
  }

  const priceModel = planEditor.querySelector<HTMLInputElement>('.js-listing-plan-option:checked')!.value

  const unitNameEls = previewPane.querySelectorAll('.js-marketplace-plan-unit-name')
  const unitNameContainer = previewPane.querySelector<HTMLElement>('.js-marketplace-plan-per-unit-info')!
  if (priceModel === 'PER_UNIT') {
    const unitName = planEditor.querySelector<HTMLInputElement>('.js-marketplace-plan-unit-name-field')!.value
    for (const unitNameEl of unitNameEls) {
      unitNameEl.textContent = unitName
      /* eslint-disable-next-line github/no-d-none */
      unitNameEl.classList.remove('d-none')
    }

    /* eslint-disable-next-line github/no-d-none */
    unitNameContainer.classList.remove('d-none')
  } else {
    for (const unitNameEl of unitNameEls) {
      /* eslint-disable-next-line github/no-d-none */
      unitNameEl.classList.add('d-none')
    }

    /* eslint-disable-next-line github/no-d-none */
    unitNameContainer.classList.add('d-none')
  }

  if (priceModel === 'FREE' || priceModel === 'DIRECT_BILLING') {
    const priceContainers = previewPane.querySelectorAll('.js-marketplace-plan-dollars-container')

    for (const priceContainer of priceContainers) {
      /* eslint-disable-next-line github/no-d-none */
      priceContainer.classList.add('d-none')
    }
  } else {
    const monthlyPriceEl = previewPane.querySelector<HTMLElement>('.js-marketplace-plan-monthly-dollars')!
    const monthlyPrice = planEditor.querySelector<HTMLInputElement>('.js-marketplace-plan-monthly-price-field')!.value
    monthlyPriceEl.textContent = monthlyPrice
    const monthlyContainer = monthlyPriceEl.closest<HTMLElement>('.js-marketplace-plan-dollars-container')!
    /* eslint-disable-next-line github/no-d-none */
    monthlyContainer.classList.remove('d-none')

    const yearlyPriceEl = previewPane.querySelector<HTMLElement>('.js-marketplace-plan-yearly-dollars')!
    const yearlyPrice = planEditor.querySelector<HTMLInputElement>('.js-marketplace-plan-yearly-price-field')!.value
    yearlyPriceEl.textContent = yearlyPrice
    const yearlyContainer = yearlyPriceEl.closest<HTMLElement>('.js-marketplace-plan-dollars-container')!
    /* eslint-disable-next-line github/no-d-none */
    yearlyContainer.classList.remove('d-none')
  }

  if (priceModel === 'DIRECT_BILLING') {
    /* eslint-disable-next-line github/no-d-none */
    noFreeTrialContainer.classList.add('d-none')
    /* eslint-disable-next-line github/no-d-none */
    freeTrialContainer.classList.add('d-none')
    /* eslint-disable-next-line github/no-d-none */
    directBillingContainer.classList.remove('d-none')
  }

  const bulletsList = previewPane.querySelector<HTMLElement>('.js-marketplace-plan-bullets')!
  bulletsList.textContent = ''

  const bulletInputs = planEditor.querySelectorAll<HTMLInputElement>('.js-marketplace-plan-bullet-field')
  const bulletTemplate = previewPane.querySelector<HTMLTemplateElement>('.js-marketplace-plan-bullet-template')!

  for (const bulletInput of bulletInputs) {
    const bulletText = bulletInput.value
    if (bulletText.trim().length < 1) {
      continue
    }

    const listItem = bulletTemplate.content.cloneNode(true)
    if (!(listItem instanceof DocumentFragment)) return
    const bulletTextEl = listItem.querySelector<HTMLElement>('.js-marketplace-plan-bullet')!
    bulletTextEl.textContent = bulletText
    bulletsList.appendChild(listItem)
  }

  const descriptionEl = previewPane.querySelector<HTMLElement>('.js-marketplace-plan-description')!
  const description = planEditor.querySelector<HTMLInputElement>('.js-marketplace-plan-description-field')!.value
  descriptionEl.textContent = description

  const accountTypeEl = previewPane.querySelector<HTMLElement>('.js-marketplace-plan-for-account-type')!
  const accountType = (
    planEditor.querySelector<HTMLElement>('.js-plan-for-account-type')!.textContent || ''
  ).toLowerCase()
  accountTypeEl.textContent = accountType
}

function arePricesAllowed(priceModel: PriceModel): boolean {
  return ['FLAT_RATE', 'PER_UNIT'].includes(priceModel)
}

function isFreeTrialAllowed(priceModel: PriceModel): boolean {
  return ['FLAT_RATE', 'PER_UNIT'].includes(priceModel)
}

function isUnitAllowed(priceModel: PriceModel): boolean {
  return priceModel === 'PER_UNIT'
}

function toggleFields(fields: NodeListOf<HTMLInputElement>, enabled: boolean) {
  for (const field of fields) {
    field.disabled = !enabled
  }
}

observe('.js-listing-plan-option:checked', {
  constructor: HTMLInputElement,
  add(selectedOption) {
    const model = selectedOption.value
    const container = selectedOption.closest<HTMLElement>('.js-marketplace-edit-plan-editor')!
    const priceFields = container.querySelectorAll<HTMLInputElement>('.js-marketplace-plan-price-field-group')
    const freeTrialFields = container.querySelectorAll<HTMLInputElement>('.js-marketplace-plan-free-trial-field-group')
    const unitFields = container.querySelectorAll<HTMLInputElement>('.js-marketplace-plan-unit-field-group')
    toggleSections(model)
    toggleFields(priceFields, arePricesAllowed(model))
    toggleFields(freeTrialFields, isFreeTrialAllowed(model))
    toggleFields(unitFields, isUnitAllowed(model))

    container.querySelector<HTMLInputElement>('.js-marketplace-plan-unit-name-field')!.required = isUnitAllowed(model)
  },
})

function toggleSections(priceModel: PriceModel) {
  for (const el of document.querySelectorAll('.js-marketplace-plan-edit-unit')) {
    if (el instanceof HTMLElement) el.hidden = !isUnitAllowed(priceModel)
  }
  for (const el of document.querySelectorAll(
    '.js-marketplace-plan-edit-price, .js-marketplace-plan-enable-free-trial',
  )) {
    if (el instanceof HTMLElement) el.hidden = !isFreeTrialAllowed(priceModel)
  }
}

on('click', '.js-marketplace-plan-preview-toggle', function (event) {
  const container = event.currentTarget.closest<HTMLElement>('.js-marketplace-edit-plan-container')!
  const editButton = container.querySelector<HTMLElement>('.js-marketplace-plan-editor-toggle')!
  const planEditor = container.querySelector<HTMLElement>('.js-marketplace-edit-plan-editor')!
  const previewPane = container.querySelector<HTMLElement>('.js-marketplace-edit-plan-preview')!

  /* eslint-disable-next-line github/no-d-none */
  if (previewPane.classList.contains('d-none')) {
    event.currentTarget.classList.add('selected')
    editButton.classList.remove('selected')
    /* eslint-disable-next-line github/no-d-none */
    previewPane.classList.remove('d-none')
    /* eslint-disable-next-line github/no-d-none */
    planEditor.classList.add('d-none')

    showPreviewOfPlan(planEditor, previewPane)
  }
})

on('click', '.js-marketplace-plan-editor-toggle', function (event) {
  const container = event.currentTarget.closest<HTMLElement>('.js-marketplace-edit-plan-container')!
  const previewButton = container.querySelector<HTMLElement>('.js-marketplace-plan-preview-toggle')!
  const planEditor = container.querySelector<HTMLElement>('.js-marketplace-edit-plan-editor')!
  const previewPane = container.querySelector<HTMLElement>('.js-marketplace-edit-plan-preview')!

  /* eslint-disable-next-line github/no-d-none */
  if (planEditor.classList.contains('d-none')) {
    event.currentTarget.classList.add('selected')
    previewButton.classList.remove('selected')
    /* eslint-disable-next-line github/no-d-none */
    previewPane.classList.add('d-none')
    /* eslint-disable-next-line github/no-d-none */
    planEditor.classList.remove('d-none')
  }
})

on('change', '.js-marketplace-plan-dollar-field', function (event) {
  const input = event.currentTarget as HTMLInputElement
  const container = input.closest<HTMLElement>('.js-marketplace-plan-dollar-field-container')!

  container.classList.toggle('is-errored', !input.checkValidity())
})
